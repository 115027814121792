import React, { useState } from "react"
import ReactPlayer from "react-player"
import Layout from "../components/layout"
import Modal from "./modal2"
import Fellestur from "../img/fellestur2.png"


export default function Aktivitetar() {
  const [show, setShow] = useState(false)
  const [title, setTitle] = useState("")

  return (
    <div>
      <Modal show={show} onClose={() => setShow(false)} title={title} />
      <Layout>
        <div>
          <img
            className="h-80 w-full object-cover object-center rounded-lg shadow-lg mt-6"
            src={Fellestur}
            alt="Stisykkel basecamp i Årdalsdalen"
          />
        </div>
        <h1 className="text-3xl text-blue-700 text-center mt-12 font-bold ">
          Påmelding til aktivitet
        </h1>
        <div className="mt-6">
          <p>
          Jølster Adventure arrangerer ulike aktivitetar gjennom året. Bruk skjema under for påmelding. 
          Sjekk  <a class="text-green-700 hover:text-green-500 underline" href="https://www.facebook.com/pg/jolsteradventure/events/">Jølster Adventure sin Facebook side</a>, 
          og <a class="text-green-700 hover:text-green-500 underline" href="https://www.facebook.com/pg/skifantomet/events/"> Skifantomet sin Facebook side</a> for meir informasjon.
          </p>
         
          <button
            onClick={() => {
              setTitle("påmelding til aktivitet")
              setShow(true)
            }}
            className="mb-3 text-green-50 px-3 py-1 rounded-md bg-blue-700 hover:bg-blue-600 mt-10 block mx-auto"
          >
            Påmelding til Jølster Adventure sine aktivitetar
          </button>
          <div className="mt-10">
           

            {/* <div className="lg:w-2/3 mx-auto">
            
              <h4 className="mr-1">
                Her er ein liten film frå ulike toppturar på Jølster.
              
              
            </h4>
              <div className="shadow-2xl mt-12 mb-8">
                <ReactPlayer
                  width="100%"
                  controls="true"
                  url="https://www.youtube.com/watch?v=34MIltAGY_I"
                  playing={false}
                />
              </div>
              <div>{" "}</div>
            </div> */}
          </div>
        </div>
      </Layout>
    </div>
  )
}
